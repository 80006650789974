@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-screen w-full overflow-x-hidden;
  }

  body {
    @apply relative h-screen w-full overflow-x-hidden bg-bg font-sans text-black antialiased dark:bg-bg-dark dark:text-white;
    padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 16px) env(safe-area-inset-bottom, 20px)
      env(safe-area-inset-left, 16px);
  }

  .default-cols {
    @apply col-span-full lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4;
  }

  .animate {
    @apply transition-all duration-300 ease-in-out;
  }

  .Toastify__toast--error {
    @apply !bg-makita-red;
  }

  .Toastify__toast--success {
    @apply !bg-button-primary dark:!bg-primary;
  }

  .Toastify__toast--warning {
    @apply !bg-button-primary dark:!bg-primary;
  }

  .Toastify__toast--info {
    @apply !bg-button-primary dark:!bg-primary;
  }
}

@layer components {
  .sticky-nav {
    @apply fixed bottom-0 left-0 rounded-t-xl border-t border-neutral-100 dark:border-border-dark;
  }

  .sticky-nav .nav-item-active {
    @apply text-primary dark:text-primary-light;
  }

  .sticky-nav .nav-item-inactive {
    @apply text-black/30 dark:text-white/30;
  }

  .nav-icon {
    @apply animate text-2xl group-hover:-translate-y-0.5;
  }

  .registration-card {
    @apply mb-8 rounded-2xl bg-white px-4 py-6 text-xs shadow-md lg:mb-0;
  }

  .discover-item h1 {
    @apply text-3xl font-bold;
  }

  .discover-item h2,
  .discover-item h3 {
    @apply mt-4 font-bold;
  }

  .discover-item h2 {
    @apply text-2xl;
  }

  .discover-item h3 {
    @apply text-xl;
  }

  .discover-item a,
  .discover-item strong {
    @apply font-bold text-primary underline hover:text-primary;
  }

  .discover-item p {
    @apply mb-4;
  }

  .instaSlider .swiper-pagination-bullet-active {
    @apply bg-primary;
  }

  .polygon-landingspage {
    @apply polygon fixed left-0 top-0 z-50 w-screen rotate-[20] bg-makita-red px-4 py-1 drop-shadow-md;
  }

  .timeline::after {
    @apply absolute left-5 top-1 z-[1] h-[calc(100%-1rem)] w-0.5 bg-primary lg:left-6;
  }

  /* Buttons */
  .btn {
    @apply animate flex cursor-pointer items-center justify-between gap-4 rounded-xl px-4 py-3 font-robotoslab font-bold shadow-md;
  }

  .btn--primary {
    @apply bg-button-primary hover:bg-primary-light hover:text-white dark:bg-primary dark:hover:bg-primary-light;
  }

  .btn--secondary {
    @apply dark:glass bg-button-secondary text-primary hover:bg-button-primary dark:text-primary-light dark:hover:bg-secondary-dark;
  }

  .has-notifications {
    @apply relative after:absolute after:right-0.5 after:top-1.5 after:h-2 after:w-2 after:rounded after:bg-makita-red;
  }
  .has-notifications--green {
    @apply after:bg-primary;
  }
}

@layer utilities {
  .error,
  .error-line {
    @apply border-t-2 pt-1 text-sm text-makita-red;
  }

  .error {
    @apply border-makita-red;
  }

  .polygon {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    -webkit-box-shadow: 0px 6px 15px 6px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 6px 15px 6px rgba(0, 0, 0, 0.16);
  }

  .bg-nav {
    background: linear-gradient(
      180deg,
      rgba(244, 244, 244, 1) 0%,
      rgba(244, 244, 244, 0.8) 90%,
      rgba(244, 244, 244, 0) 100%
    );
    @apply backdrop-blur;
  }

  .bg-nav-dark {
    background: rgba(10, 10, 10, 0.3);
    @apply backdrop-blur;
  }

  .glass {
    border: 1px solid var(--StrokeGradient, rgba(33, 38, 50, 0.1));
    background: var(--GlassBgGradient, linear-gradient(95deg, rgba(33, 38, 50, 0.5) 0%, rgba(33, 38, 50, 0.7) 100%));
    box-shadow:
      0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    @apply backdrop-blur;
  }

  .glass--dark {
    background: var(--GlassBgGradient, linear-gradient(95deg, rgba(33, 38, 50, 0.7) 0%, rgba(33, 38, 50, 0.9) 100%));
  }

  .loader {
    width: var(--loader-width);
    height: var(--loader-height);
    background: linear-gradient(#0000 var(--gradient-stop), #008497 0 calc(3 * var(--gradient-stop)), #0000 0),
      linear-gradient(#0000 calc(2 * var(--gradient-stop)), #008497 0 calc(4 * var(--gradient-stop)), #0000 0),
      linear-gradient(#0000 calc(3 * var(--gradient-stop)), #008497 0 calc(5 * var(--gradient-stop)), #0000 0);
    background-size: 10px 400%;
    background-repeat: no-repeat;
    animation: matrix 1s infinite linear;
  }

  @keyframes matrix {
    0% {
      background-position:
        0% 100%,
        50% 100%,
        100% 100%;
    }
    100% {
      background-position:
        0% 0%,
        50% 0%,
        100% 0%;
    }
  }
}

:root {
  --toastify-color-error: #c20016 !important;
  --loader-width: 45px;
  --loader-height: 40px;
  --gradient-stop: calc(100% / 6);
  --StrokeGradient: linear-gradient(135deg, rgba(33, 38, 50, 0.1) 0%, rgba(33, 38, 50, 0.5) 100%);
  --GlassBgGradient: linear-gradient(135deg, rgba(33, 38, 50, 0.45) 0%, rgba(33, 38, 50, 0.6) 100%);
}
